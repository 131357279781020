<template>
  <svg
    version="1.1"
    id="L5"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enable-background="new 0 0 0 0"
    xml:space="preserve"
    :style="fillStyle"
    :width="width"
    :height="height"
    :class="cssClass"
  >
    <circle stroke="none" cx="6" cy="50" r="6">
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 15 ; 0 -15; 0 15"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle stroke="none" cx="30" cy="50" r="6">
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 10 ; 0 -10; 0 10"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle stroke="none" cx="54" cy="50" r="6">
      <animateTransform
        attributeName="transform"
        dur="1s"
        type="translate"
        values="0 5 ; 0 -5; 0 5"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {
      fillStyle: `enable-background: new 0 0 504 421.7; fill:${this.fillColor}`,
    };
  },
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    fillColor: {
      type: String,
      required: false,
    },
    cssClass: {
      type: String,
      required: false,
    },
  },
};
</script>
