// Number formatting utilities
export default {
  // Function that returns the provided number with a specified format and a variable maximum number of decimal digits
  /**
   *
   * @param {number} decimals - maximum number of decimals
   * @param {number} num - number to be processed
   * @returns the formatted number
   */
  get(num, decimals) {
    const formatedNum = new Intl.NumberFormat("it", {
      style: "decimal",
      maximumFractionDigits: typeof decimals !== "undefined" ? decimals : 0,
      minimumFractionDigits: 0,
    }).format(num);
    return formatedNum === "-0" ? "0" : formatedNum;
  },
  get2digits(number) {
    return new Intl.NumberFormat("it", {
      minimumIntegerDigits: 2,
    }).format(number);
  },
};
