/**
 * @description This function gets the name of the selected Area
 */
export default (params, messages) => {
  for (const key in params) {
    if (params[key]) {
      if (key === 'countryCode' || key === 'regionalCode') {
        return messages.areaByCode[params[key]];
      } else if (key === 'concessionCode'){
        return getConcessionName(params[key]);
      } else if (key === 'aspm') {
        return params[key];
      }
    }
  }
};

export const getConcessionName = (concessionCode) => {
  const concessions = document.getElementById('concessions');
  if(concessions) {
    const concessionsList = concessions.options;
    for (let i = 0; i < concessionsList.length; i++) {
        if (concessionsList[i].value == concessionCode) {
            return concessionsList[i].text;
        }
    }
  }
  return null;
}
