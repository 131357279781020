export default {
  get(id) {
    switch (String(id)) {
      case "1":
        return "es";
      case "2":
        return "pt";
      default:
        return "es";
    }
  },
  getId(string) {
    switch (string) {
      case "es":
        return 1;
      case "pt":
        return 2;
      default:
        return 1;
    }
  },
  getByConcessionCode(string) {
    const firstDigits = string.substr(0, 2);
    switch (firstDigits) {
      case "02":
        return {
          id: 1,
          str: "es",
        };
      case "10":
        return {
          id: 2,
          str: "pt",
        };
      default:
        return {
          id: 1,
          str: "es",
        };
    }
  },
};
