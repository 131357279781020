<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          block
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol xl="4" v-show="showBools.FiltersAreaCombo">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
              <CCol xl="4">
                <label class="filter-label">FY</label>
                <FilterFY v-show="showBools.FilterFY" />
              </CCol>
              <CCol xl="4">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterFYPeriod
                  v-show="showBools.FilterFYPeriod"
                  :inactive="!showBools.FilterFYPeriod"
                />
                <FilterMonthsRange
                  v-show="showBools.FilterMonthsRange"
                  :inactive="!showBools.FilterMonthsRange"
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader1" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable1" class="fadeIn anim-speed--2s">
      <CCol xl="12">
        <TableIncentives :tableData="incentiveData">
          <span slot="area">{{ selectedArea }}{{ selectedArea && ' - ' }}</span> 
          <span slot="period">{{ selectedPeriod }}</span> 
          <span slot="fiscalYear">{{ selectedFY }}</span>
        </TableIncentives>
      </CCol>
    </CRow>
    <CRow v-if="showLoader2" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable2" class="fadeIn anim-speed--2s">
      <CCol xl="12">
        <TableBasicConditions :tableData="conditionsData" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Api from "@/services/Api";
const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filtersAreaCombo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const FilterFYPeriod = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year-period" */ "@/components/molecules/FilterFYPeriod"
  );
const FilterMonthsRange = () =>
  import(
    /* webpackChunkName: "filterMonthsRange" */ "@/components/molecules/FilterMonthsRange"
  );

const TableIncentives = () =>
  import(
    /* webpackChunkName: "tableIncentives" */ "@/components/organisms/TableIncentives"
  );

const TableBasicConditions = () =>
  import(
    /* webpackChunkName: "tableBasicConditions" */ "@/components/organisms/TableBasicConditions"
  );

import Loader from "@/components/atoms/Loader";
import formatNum from "@/utils/formatNum";
import langStr from "@/utils/langStr";
import currentFY from "@/services/CurrentFY";
import filtersByProfile from "@/helpers/filtersByProfile";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import calculatePeriod from "@/helpers/calculatePeriod";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

export default {
  name: "DealerIncentiveEstimation",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterFYPeriod,
    FilterMonthsRange,
    Loader,
    TableIncentives,
    TableBasicConditions,
  },
  data() {
    return {
      filtersList: null,
      activeItems: [],
      collapse: false,
      cardCollapse: true,
      isCollapsed: true,
      areaError: false,
      user: null,
      lang: null,
      messages: null,
      showLoader1: false,
      showLoader2: false,
      showTable1: false,
      showTable2: false,
      incentiveData: [],
      conditionsData: [],
      showBools: {
        FiltersAreaCombo: false,
        FilterFY: false,
        FilterFYPeriod: false,
        FilterMonthsRange: false,
      },
      selectedArea: null,
    };
  },
  computed: {
    implicitParams() {
      const n = this.user.profileId;
      if (n >= 20 && n < 30) {
        return {
          concessionCode: this.user.concessionCode,
        };
      }
      return null;
    },
  },
  methods: {
    makeDefaultQuery() {
      currentFY().then((currentFY) => {
        const defaultArea = defaultAreaParam(this.user);
        const defaults = {};
        const areaKey = defaultArea.key;
        defaults.area = {
          countryCode: "",
          regionalCode: "",
          aspm: "",
          concessionCode: "",
        };
        defaults.area[areaKey] = defaultArea.value;
        defaults.fy = { fiscalYear: currentFY };
        defaults.month = { month: new Date().getMonth() + 1 };
        const period = calculatePeriod(currentFY);

        defaults.sinceMonth = { sinceMonth: period.start };
        defaults.upToMonth = { upToMonth: period.end };

        this.makeApiQueries(defaults);
      });
    },
    submitFilterValues(event) {
      event.preventDefault();

      let params,
        validated = false;
      if (this.implicitParams) {
        params = { area: { ...this.implicitParams } };
        validated = true;
      } else {
        params = { area: { ...this.$store.getters.getFiltersAreaComboParams } };
        if (!this.$store.getters.getFiltersAreaComboParams) {
          this.areaError = true;
        } else {
          this.areaError = false;
          validated = true;
        }
      }

      if (validated) {
        const fiscalYear = this.$store.getters.getFilterFYValue;
        params.fy = { fiscalYear: fiscalYear };
        params.sinceMonth = {
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
        };
        params.upToMonth = {
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
        };

        this.makeApiQueries(params);
      }
    },
    makeApiQueries(params) {
      this.showLoader1 = true;
      this.showTable1 = false;
      this.showLoader2 = true;
      this.showTable2 = false;

      params.langId = { languageId: langStr.getId(this.lang) };

      const paramsTable1 = {
        ...params.area,
        ...params.fy,
        ...params.sinceMonth,
        ...params.upToMonth,
      };
      this.setTableTitle(params);

      this.getDealerIncentive(paramsTable1).then((results) => {
        this.showLoader1 = false;
        this.showTable1 = true;
        this.incentiveData = this.processIncentiveData(results);
      });

      const paramsTable2 = {
        ...params.area,
        ...params.fy,
        ...params.sinceMonth,
        ...params.upToMonth,
        productCodes: ["REMANVA", "T03", "T05", "BPC"],
      };

      this.getBasicConditions(paramsTable2).then((data) => {
        if (data) {
          this.showLoader2 = false;
          if (data.length > 0) {
            this.showTable2 = true;
            this.processConditionsData(data);
          }
        }
      });
    },
    async getDealerIncentive(params) {
      const results = await Api.sendPost(
        "commercial/concessionIncentive",
        params
      );
      return results.data.data;
    },
    async getBasicConditions(params) {
      const response = await Api.sendPost(
        "commercial/concessionIncentive/condition",
        params
      );
      if (response.status === 200 && response.data.code === 200)
        return response.data.data;
      return null;
    },
    processIncentiveData(rawData) {
      const list = rawData.contentList;
      list.forEach((row) => {
        row._classes = "focus";
        if (row.description.toUpperCase().includes("TOTAL")) {
          row._classes = "totals";
          row.description = "";
          row.completion = `TOTAL ${this.messages.dealerIncentiveEstimation.incentives.toUpperCase()}`;
          row.incentiveDiff = "";
        }
        Object.keys(row).forEach((key) => {
          if (row[key] === null) {
            return (row[key] = "");
          } else if (typeof row[key] === "number") {
            let units = "€";
            if (key === "completion") {
              units = "%";
            }
            return (row[key] = `${formatNum.get(row[key], 2)}${units}`);
          }
        });
      });
      const totalPurchasesRow = {
        description: "",
        targetPurchases: "",
        purchasesCount: "",
        purchasesCharge: "",
        completion:
          this.messages.dealerIncentiveEstimation.totalPurchases.toUpperCase(),
        incentive: `${formatNum.get(rawData.realPurchases, 2)}€`,
        maxIncentive: "",
        incentiveDiff: "",
        _classes: "totals",
      };
      const totalPurchasesPercentRow = {
        description: "",
        targetPurchases: "",
        purchasesCount: "",
        purchasesCharge: "",
        completion: this.messages.dealerIncentiveEstimation.percentageOverTotal,
        incentive: `${formatNum.get(rawData.realPurchasesCompletionRate, 2)}%`,
        maxIncentive: "",
        incentiveDiff: "",
        _classes: "totals",
      };
      list.push(totalPurchasesRow);
      list.push(totalPurchasesPercentRow);
      return list;
    },
    processConditionsData(rawData) {
      this.conditionsData = rawData.map((elem) => {
        return {
          productCode: elem.productCode,
          productKey: elem.targetCondition,
          complies: Number(elem.currentCondition >= elem.targetCondition),
        };
      });
    },
    // Refactor
    mountFilters(filtersNames) {
      filtersNames.forEach((elem) => {
        if (typeof elem === "string") {
          this.showBools[elem] = true;
        } else if (typeof elem === "object") {
          this.showBools.FiltersAreaCombo = true;
          this.activeItems = elem.FiltersAreaCombo;
        }
      });
    },
    setTableTitle(params) {
      this.selectedArea = getAreaName(params.area, this.messages);
      this.selectedFY = formatFiscalYear(params.fy.fiscalYear);
      const sinceMonth = this.messages.filters.months[params.sinceMonth.sinceMonth];
      const upToMonth = this.messages.filters.months[params.upToMonth.upToMonth]
      this.selectedPeriod = sinceMonth === upToMonth ? sinceMonth : `de ${sinceMonth} a ${upToMonth}`;
    },
  },
  created() {
    this.user = this.$store.getters.getCurrentUser;
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    if (this.implicitParams) this.makeDefaultQuery();
    this.filtersList = filtersByProfile(
      "dealerIncentiveEstimation",
      this.user.profileId
    );
    this.mountFilters(this.filtersList);
  },
};
</script>
