/**
 * @description Returns an array of selected filters per profile
 */
export default (report, profileId) => {
  const schema = {
    referenceNumbers: [
      { rule: (n) => n < 30, result: ["FilterFY"] },
      {
        rule: (n) => n < 20,
        result: [
          {
            FiltersAreaCombo: [
              "countries",
              "regionals",
              "aspms",
              "concessions",
            ],
          },
        ],
      },
    ],
    controlPanel: [
      {
        rule: (n) => n < 30,
        result: ["FilterFY", "FilterMonth", "FilterIsCumulative"],
      },
      {
        rule: (n) => n < 20,
        result: [
          {
            FiltersAreaCombo: [
              "countries",
              "regionals",
              "aspms",
              "concessions",
            ],
          },
        ],
      },
    ],
    purchasesTracking: [
      {
        rule: (n) => n < 30,
        result: ["FilterFY", "FilterMonth", "FilterMonthsRange"],
      },
      {
        rule: (n) => n < 20,
        result: [
          {
            FiltersAreaCombo: [
              "countries",
              "regionals",
              "aspms",
              "concessions",
            ],
          },
        ],
      },
    ],
    dealerIncentiveEstimation: [
      {
        rule: (n) => n >= 20 && n < 30,
        result: ["FilterFY", "FilterFYPeriod"],
      },
      {
        rule: (n) => n < 20,
        result: [
          "FilterFY",
          "FilterMonthsRange",
          {
            FiltersAreaCombo: ["concessions"],
          },
        ],
      },
    ],
  };

  const getFilters = (report, n) => {
    const filters = [];
    report.forEach((elem) => {
      if (elem.rule(n)) {
        elem.result.forEach((item) => filters.push(item));
      }
    });
    return filters;
  };

  return getFilters(schema[report], profileId);
};
