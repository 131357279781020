/**
 * @description This function calculates the period (quarter) to be considered by default
 */
export default (currentFY) => {
  // Initially end month is the Date month + 1 (current month)
  let end = new Date().getMonth() + 1;
  // let end = 3;

  // Reference Quarter
  const refQ = parseInt(end / 3);
  // Quarter to show
  // If the current month is one of the first 3 months of real year (is <= 3) then if remainder of division is 0 or 1 the reference quarter must be the quarter before the current quarter
  const quarterToShow = end > 3 ? (end % 3 <= 1 ? refQ - 1 : refQ) : 4;

  // Calculate first month of quarter
  let start =
    quarterToShow * 3 + 1 > 12
      ? quarterToShow * 3 + 1 - 12
      : quarterToShow * 3 + 1;

  // If the current month is April, the fiscal year must be the previous fiscal year
  const fiscalYear = end === 4 ? currentFY - 1 : currentFY;

  // If the current month is the first month of a quarter, the final month is the last month of the previous quarter
  if ([1, 4, 7, 10].includes(end)) {
    // If the current month is January, the start and the end of the period is the last quarter of the last year but the same fiscal year
    if (end - 1 === 0) {
      start = 10;
      end = 12;
    } else {
      end = end - 1;
    }
  }

  return {
    start: start,
    end: end,
    year: fiscalYear,
  };
};
