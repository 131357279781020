export default (user) => {
  const n = user.profileId;
  if (n >= 20 && n < 30) {
    return {
      key: "concessionCode",
      value: user.concessionCode,
    };
  } else if (n >= 10 && n < 20) {
    return {
      key: "aspm",
      value: user.aspm,
    };
  } else if (n <= 0) {
    return {
      key: "countryCode",
      value: user.countryCode,
    };
  }
  return {
    key: "countryCode",
    value: user.countryCode,
  };
};
